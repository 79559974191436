<template>
    <div>
        <template v-if="type === 'created_by'">
            <span v-if="createdByName === ''">
                Admin
            </span>
            <span
                v-else
                class="text-link"
                @click="$emit('callback')">
                {{ createdByName }}
            </span>
        </template>
        <template v-else>
            <router-link
                v-if="isAccount"
                :to="{name: 'dealer', params: { dealer_id: item.agency_id }}">
                {{ item.agency.name }}
            </router-link>
            <template v-else>
                <span v-if="performanceManagerName === ''">
                    Admin
                </span>
                <span
                    class="text-link"
                    @click="$emit('callback')">
                    {{ performanceManagerName }}
                </span>
            </template>
        </template>
    </div>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        createdByName() {
            return this.item.created_by_user?.name ?? '';
        },
        performanceManagerName() {
            return this.item.performance_manager?.name ?? '';
        },
        isAccount() {
            if (this.item.group_type === 'Organization') { return true}
            return false;
        }
    }
};
</script>